<template>
  <div class="every-container">
    <div class="head">
      <div class="left">
        <img
          v-if="every.createUserImage"
          @click="goAutherDetail"
          v-lazy="$global.imgUrl + every.createUserImage"
          alt=""
        />
        <img v-else :src="require('@/assets/imgs/head_bg.png')" />
        <div class="info">
          <div class="name" @click="goAutherDetail">
            <span v-if="every.createUserName">{{ every.createUserName }}</span>
            <svg
              id="图层_1"
              data-name="图层 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 170 170"
            >
              <title>icon-sch</title>
              <circle class="cls-1" cx="119.88" cy="119.88" r="48.12" />
              <path
                class="cls-1"
                d="M67.19,129c3.4,28.13,31.16,38.44,31.16,38.44-9.18,1.34-18.71.52-29.89.52-35.8,0-64.81-1.86-64.81-37.66A64.78,64.78,0,0,1,35,74.8,51.26,51.26,0,0,0,68.46,87.88a31.33,31.33,0,0,0,9.17-1.17S63.79,100.9,67.19,129Z"
              />
              <circle class="cls-1" cx="68.46" cy="41.67" r="39.42" />
              <path
                class="cls-2"
                d="M89.16,122l21.28,19.13a9.81,9.81,0,0,0,14.24-1.2l27.73-35"
              />
            </svg>
          </div>
          <ul class="icon">
            <li v-for="(icon, i) in every.createUserHonourList" :key="i">
              <img v-lazy="$global.imgUrl1 + icon.content" alt="" />
            </li>
          </ul>
        </div>
      </div>
      <div class="right">
        <div>
          <span v-if="every.createDate">{{
            every.createDate | dayFormate
          }}</span
          >-
          <span class="project" @click="goCircle(every.circleId)">{{
            every.circleTitle
          }}</span>
        </div>
        <Button
          type="primary"
          v-throttleClick="2000"
          shape="circle"
          class="btn"
          v-if="!everyObj.flag"
          @click="focusPro(every.id)"
          >关注问题</Button
        >
        <Button
          type="primary"
          v-throttleClick="2000"
          shape="circle"
          class="btn"
          v-else
          @click="focusPro(every.id, true)"
          >已关注问题</Button
        >
      </div>
    </div>
    <div class="main">
      <div class="title" @click="goDetails">
        {{ every.title }}
      </div>
      <div class="content">
        {{ every.content }}
      </div>
    </div>
    <div class="comment" v-if="every.replyContent">
      <div class="top">
        <Button @click="goDetails" class="btn" shape="circle"
          >{{ every.replyNum }}个回复</Button
        >
        <span class="date">{{ every.replyTime | dayFormate }}</span>
      </div>
      <div class="message">
        <img v-lazy="$global.imgUrl + every.replyUserImage" alt="" />
        <span class="name" @click="goAutherReplyDetail">{{
          every.replyUserName
        }}</span>
        <svg
          id="图层_1"
          data-name="图层 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 170 170"
        >
          <title>icon-sch</title>
          <circle class="cls-1" cx="119.88" cy="119.88" r="48.12" />
          <path
            class="cls-1"
            d="M67.19,129c3.4,28.13,31.16,38.44,31.16,38.44-9.18,1.34-18.71.52-29.89.52-35.8,0-64.81-1.86-64.81-37.66A64.78,64.78,0,0,1,35,74.8,51.26,51.26,0,0,0,68.46,87.88a31.33,31.33,0,0,0,9.17-1.17S63.79,100.9,67.19,129Z"
          />
          <circle class="cls-1" cx="68.46" cy="41.67" r="39.42" />
          <path
            class="cls-2"
            d="M89.16,122l21.28,19.13a9.81,9.81,0,0,0,14.24-1.2l27.73-35"
          />
        </svg>
        <ul class="icon">
          <li v-for="(icon, i) in every.replyUserHonourList" :key="i">
            <img v-lazy="$global.imgUrl1 + icon.content" alt="" />
          </li>
        </ul>
        <span class="date">{{ every.replyTime | dayFormate }}</span>
      </div>
      <div class="box">{{ every.replyContent }}</div>
      <Button @click="goDetails" class="all-btn" shape="circle"
        >全部回复<Icon type="ios-arrow-round-forward"
      /></Button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    every: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      everyObj: this.every,
    };
  },
  computed: {
    ...mapState(["hasLogin"]),
  },
  methods: {
    // 关注问题接口
    async focusPro(id, status = false) {
      if (this.hasLogin) {
        // 0 关注 1 取消关注
        let data = {
          attentionId: id,
          type: status ? 0 : 1,
        };
        const resp = await this.$apis.circleServe.onFocusPro(data);
        if (resp.resultDesc === "成功!") {
          this.everyObj.flag = !status;
          // this.$Message.success(resp.resultDesc);
          this.$Message.success(status ? "取消关注成功" : "关注成功");
        } else {
          this.$Message.error(resp.resultDesc);
        }
      } else {
        this.$store.commit("showAlert", true);
      }
    },
    goDetails() {
      let url = this.$router.resolve({
        path: "/discussdetails",
        query: {
          id: this.every.id,
        },
      });
      window.open(url.href, "_blank");
    },
    goAutherDetail() {
      let url = this.$router.resolve({
        path: "/autherdetail",
        query: {
          id: this.every.createUserId,
        },
      });
      window.open(url.href, "_blank");
    },
    goAutherReplyDetail() {
      let url = this.$router.resolve({
        path: "/autherdetail",
        query: {
          id: this.every.replyUser,
        },
      });
      window.open(url.href, "_blank");
    },
    goCircle(id) {
      let url = this.$router.resolve({
        path: "/detaildiscuss",
        query: {
          id,
        },
      });
      window.open(url.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.cls-1 {
  fill: #00a4ff;
}
.cls-2 {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 7px;
}
.every-container {
  margin-top: 30px;
  width: 1040px;
  // height: 661px;
  background: #ffffff;
  border: 1px dotted #999999;
  border-radius: 12px;
  padding: 30px;
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    .left {
      display: flex;
      img {
        width: 60px;
        height: 60px;
        margin-right: 15px;
        border-radius: 50%;
        cursor: pointer;
      }
      .info {
        .name {
          font-size: 22px;
          color: #444444;
          cursor: pointer;
          span {
            margin-right: 6px;
          }
          svg {
            width: 18px;
            height: 18px;
          }
        }
        .icon {
          margin-top: 5px;
          display: flex;
          li {
            width: 17px;
            height: 17px;
            margin: 0 3px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .right {
      text-align: right;
      div {
        font-size: 15px;
        color: #999999;
        margin-bottom: 15px;
        .project {
          color: #00a4ff;
          cursor: pointer;
        }
      }
      .btn {
        width: 120px;
        height: 38px;
      }
    }
  }
  .main {
    margin: 29px 0;
    width: 980px;
    // height: 267px;
    .title {
      font-size: 24px;
      color: #00a4ff;
      cursor: pointer;
    }
    .content {
      margin-top: 18px;
      font-size: 16px;
      color: #333333;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      text-overflow: ellipsis;
      text-align: justify;
    }
  }
  .comment {
    .top {
      margin-bottom: 15px;
      .btn {
        width: 120px;
        height: 38px;
        margin-right: 15px;
        border: 1px solid #00a4ff;
        color: #999999;
      }
    }
    .date {
      font-size: 14px;
      color: #999999;
      margin-left: 10px;
    }
    .message {
      display: flex;
      align-items: center;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
      .name {
        font-size: 18px;
        color: #444444;
        margin: 0 10px;
        cursor: pointer;
      }
      svg {
        width: 18px;
        height: 18px;
      }
      .icon {
        margin-top: 5px;
        margin-left: 10px;
        display: flex;
        li {
          width: 17px;
          height: 17px;
          margin: 0 3px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .box {
      margin-top: 9px;
      font-size: 14px;
      color: #333333;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .all-btn {
      width: 120px;
      height: 38px;
      border: 1px solid #00a4ff;
      margin-top: 21px;
      color: #00a4ff;
    }
  }
}
</style>
