<template>
  <div class="recommend-circle-container">
    <div class="container">
      <Every v-for="(item, i) in everyList" :key="i" :every="item"></Every>
      <div class="no-results" v-if="total === 0 && !isLoding">
        <img :src="require('@/assets/qietu/dynamic.png')" alt="">
        <p>暂无推荐</p>
      </div>
      <p v-show="isLoding" style="text-align: center;color: rgb(105, 98, 98);">加载中....</p>
    </div>
    <Side :sideList="sideList" title="热门学者"></Side>
  </div>
</template>

<script>
import Side from "../components/Side.vue";
import Every from "../components/Every.vue";
export default {
  components: {
    Every,
    Side,
  },
  data() {
    return {
      isLoding: false, // 加载
      pageNum: 1, // 当前页数
      total: 0, // 推荐总数
      everyList: [],
      sideList: [
        {
          img: "https://tse1-mm.cn.bing.net/th?id=OIP-C.fR9s9Ys1hzRMIURSfhgBAwAAAA&w=145&h=160&c=8&rs=1&qlt=90&o=6&pid=3.1&rm=2",
          name: "李磊",
          school: "芝加哥大学",
        },
      ],
    };
  },
  created() {
    this.circleIndexRecommendPage({
      pageNum: 1,
      pageSize: 2,
    });
    this.getHotScholarList();
  },
  methods: {
    // 监听滚动条
    handleScroll() {
      const homeDoc = document.getElementsByClassName('home-container')
      if ((document.documentElement.scrollTop+document.body.offsetHeight) == homeDoc[0].offsetHeight ) {
        if (this.total / 2 > this.pageNum && !this.isLoding) {
          this.circleIndexRecommendPage({
            pageNum: this.pageNum + 1,
            pageSize: 2,
          });
        }
      }
    },
    async circleIndexRecommendPage(data) {
      this.isLoding = true
      const resp = await this.$apis.circleServe.circleIndexRecommendPage(data);
      if (resp.data) {
        this.everyList.push(...resp.data.list);
        this.pageNum = +resp.data.pageNum  
        this.total = +resp.data.total
      }
      this.isLoding = false
      window.addEventListener('scroll',this.handleScroll) // 给滚动条添加事件
    },
    async getHotScholarList() {
      const resp = await this.$apis.circleServe.getHotScholarList();
      this.sideList = resp.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.recommend-circle-container {
  display: flex;
  align-items: flex-start;
  .container {
    width: 1040px;
    margin-right: 40px;
    .no-results {
      width: 483px;
      margin: 20px auto 0;
      img {
        width: 100%;
      }
      p {
        text-align: center;
        font-size: 16px;
        color: #999999;
      }
    }
  }
}
</style>
